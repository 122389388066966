<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState v-model="state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('create')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('create_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { onMounted } from 'vue';
// Components
import WindowLayout from '@/layouts/Window.layout.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

// Services
import useAyudaDiagnostica from './services/useAyudaDiagnosticas';
import useAyudaDiagnosticaForm from './services/useAyudaDiagnosticaForm';

export default {
    name: 'MedicamentoForm',
    components: {
        WindowLayout,
        SelectInputSimpleState,
    },
    props: {
        title: { type: String, default: 'Ayudas Diagnosticas' },
        headerTitle: { type: String, default: 'Ingresar Ayuda Diagnostica' },
        item: Object,
    },
    setup(props) {
        // eslint-disable-next-line object-curly-newline
        const ayudaService = useAyudaDiagnostica();
        // eslint-disable-next-line object-curly-newline
        const { state, setState, v, handleAction } = useAyudaDiagnosticaForm(ayudaService);

        /** ***** Lifecycle Hooks ***** */
        onMounted(async () => {
            if (props.item) {
                await Promise.all([ayudaService.getAyuda(props.item.id)]);
                if (ayudaService.ayuda.value) {
                    setState(ayudaService.ayuda.value);
                }
            }
        });

        return {
            state,
            handleAction,
            v,
        };
    },
};
</script>

<style></style>
