import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useAyudaDiagnostica() {
    const ayuda = ref(null);
    const ayudas = ref([]);

    const getAyuda = async (id) => {
        // ayuda.value = ayudas.value.find((d) => id === d.id);
        const res = await axios.get(`/care/ayudas-diagnosticas/${id}`);

        if (res.status === 200) {
            ayuda.value = res.data;
        }
    };

    const getAyudas = async (searchQuery) => {
        // console.log('Mock');
        // ayudas.value = [
        //     { selectName: 'ORDEN 1', id: 1 },
        //     { selectName: 'ORDEN 2', id: 2 },
        //     { selectName: 'ORDEN 3', id: 3 },
        //     { selectName: 'ORDEN 4', id: 4 },
        //     { selectName: 'ORDEN 5', id: 5 },
        //     { selectName: 'ORDEN 6', id: 6 },
        //     { selectName: 'ORDEN 7', id: 7 },
        //     { selectName: 'ORDEN 8', id: 8 },
        //     { selectName: 'ORDEN 9', id: 9 },
        //     { selectName: 'ORDEN 10', id: 10 },
        //     { selectName: 'ORDEN 11', id: 11 },
        //     { selectName: 'ORDEN 12', id: 12 },
        // ];
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/ayudas-diagnosticas${query}`);

        if (res.status === 200) {
            ayudas.value = res.data.items;
        }
    };

    const createAyuda = async (data) => {
        const res = await axios.post('/care/ayudas-diagnosticas', data);
        if (res.status === 201) {
            // console.log(res.data);
            ayuda.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateAyuda = async (id, data) => {
        const res = await axios.put(`/care/ayudas-diagnosticas/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            ayuda.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeAyuda = async (id) => {
        const res = await axios.delete(`/care/ayudas-diagnosticas/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            ayuda.value = undefined;
            if (ayudas.value) {
                ayudas.value = ayudas.value.filter((e) => e.id !== id);
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        ayuda,
        ayudas,
        getAyuda,
        getAyudas,
        createAyuda,
        updateAyuda,
        removeAyuda,
    };
}
